import { AxiosError, AxiosRequestConfig } from 'axios';
import API_URLS from '../../constants/api-urls';
import Routes from '../../constants/routes';
import oauth from '../../services/authorization/oauth';
import axios from '../axios';
import isMobileAppWebview from '../is-mobile-app-webview';
import session from '../session';
import serializeQueryParams from '../url/serialize-query-params';
import should401BeIgnored from './should-401-be-ignored';

const LIMIT = 5;
const response401ErrorsCountTracker = {};

const appendAuthHeader = (config: AxiosRequestConfig) => {
    if (session.accessTokenValid() && config?.headers) {
        config.headers.Authorization = `Bearer ${session.getAccessToken()}`;
    }

    return config;
};

const appendCartHeader = (config: AxiosRequestConfig) => {
    const params = serializeQueryParams();

    if (window.storage.getItem('cart_token') && config?.headers) {
        config.headers['X-Cart-Token'] = window.storage.getItem('cart_token');
    }

    if (params.c_t && config?.headers) {
        config.headers['X-Cart-Token'] = params.c_t as string;
    }

    return config;
};

export const requestInterceptor = (config: AxiosRequestConfig) => {
    const url = `${config.baseURL}${config.url}`;

    if (
        config.method === 'POST' &&
        config?.headers &&
        (!config.data!.file || !(config.data!.file[0] instanceof File))
    ) {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    if (url.indexOf('/api/v3/oauth2/validate') >= 0) {
        return appendAuthHeader(config);
    }

    if (url.indexOf(API_URLS.CART) >= 0) {
        appendCartHeader(config);
    }

    if (url.substr(-27, 27) === '/api/v3/oauth2/authenticate' || url.indexOf('/api/v') === -1) {
        return config;
    }

    if (oauth.isAgbAccepted() && !config?.params?.noValidate) {
        return new Promise<AxiosRequestConfig>((resolve) => {
            oauth.validate().then(() => {
                resolve(appendAuthHeader(config));
            });
        });
    }

    return appendAuthHeader(config);
};

const exceededRetryQuota = (url: string | undefined): boolean => {
    if (!url) {
        return false;
    }

    response401ErrorsCountTracker[url] = (response401ErrorsCountTracker[url] || 0) + 1;

    return response401ErrorsCountTracker[url] > LIMIT;
};

const resetTrackedResponseErrors = (url: string | undefined) => {
    if (url) {
        response401ErrorsCountTracker[url] = 0;
    }
};

export const responseInterceptor = (rejectionResp: AxiosError<{ reason: string; message: string }>) => {
    const { response: rejection } = rejectionResp;

    const isValidateURL = (rejection?.config.url || '').indexOf(API_URLS.VALIDATE) >= 0;

    const is401Error = rejection?.status === 401;

    if (isValidateURL && is401Error && session.isLoggedIn()) {
        const isAgbDeclined = rejection?.data?.reason === 'terms_not_accepted';

        if (isAgbDeclined || isMobileAppWebview()) {
            return Promise.reject(rejection);
        }

        session.setLoggedIn(0);
        window.locationReload(Routes.LOGIN.path);

        return Promise.reject(rejection);
    }

    if (
        is401Error &&
        oauth.isAgbAccepted() &&
        !rejection?.config?.params?.noValidate &&
        !should401BeIgnored(rejection.config.url as string)
    ) {
        if (
            exceededRetryQuota(rejection.config.url) ||
            rejection.config.url!.indexOf(API_URLS.AUTHENTICATE) >= 0 ||
            rejection.config.url!.indexOf(API_URLS.ITEM_TAGS) >= 0
        ) {
            return Promise.reject(rejection);
        }

        return new Promise((resolve) => {
            oauth.login('clientCredentials').then(() => {
                axios(rejection.config).then((res) => {
                    resolve({ data: res });
                });
            });
        });
    }

    if (rejection && rejection.status !== 401) {
        resetTrackedResponseErrors(rejection.config.url);
    }

    if (axios.isCancel(rejectionResp)) {
        return Promise.reject(rejectionResp);
    }

    return Promise.reject(rejection);
};
