import { FORBIDDEN_ENDPOINTS } from './forbidden-endpoints.constants';

export const isForbiddenEndpointsOnPages = (endpoint: string): boolean => {
    const currentUrl = window.location.href;

    return Object.entries(FORBIDDEN_ENDPOINTS).some(([page, forbiddenEndpoints]) => {
        return (
            currentUrl.includes(page) &&
            forbiddenEndpoints.some((forbiddenEndpoint) => endpoint.includes(forbiddenEndpoint))
        );
    });
};
