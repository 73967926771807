import API_URLS from '../../constants/api-urls';
import ROUTES from '../../constants/routes';

export const FORBIDDEN_ENDPOINTS = {
    [ROUTES.PAYMENT.path]: [
        API_URLS.FILTERS,
        API_URLS.SUBJECTS_MENU,
        API_URLS.SUBSCRIPTION.ANONYMOUS_PERSIST,
        `${API_URLS.WISHLIST}/query`,
        `${API_URLS.ANONYMOUS_WISHLIST}/query`
    ]
};
