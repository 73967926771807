import API_URLS from '../constants/api-urls';
import axios from '../utils/axios';
import serializeQueryParams from '../utils/url/serialize-query-params';

const countryByIP: Service = {
    get: async () => {
        const queryParams = serializeQueryParams();
        // const userID = window.storage.getItem('userId');

        if (queryParams.countryCode && queryParams.countryName) {
            await axios.post(API_URLS.SET_COUNTRY, {
                code: queryParams.countryCode,
                name: queryParams.countryName,
                expires_in: '1y'
            });
        }

        return axios.get(API_URLS.GET_COUNTRY, {});
    }
};

export default countryByIP;
