import { ParsedQuery } from 'query-string';
import API_URLS from '../constants/api-urls';
import axios from '../utils/axios';
import cookiesManager from '../utils/browser-storage/cookies';
import detectBrowser from '../utils/detect-browser';
import detectDevice from '../utils/detect-device';
import { CatalogProps } from '../utils/enhanced-ecommerce';
import isPrerender from '../utils/is-prerender';
import isUITest from '../utils/is-ui-test';
import isNewMaterial from '../utils/material/is-new-material';
import { getPosthogFlagsCalled } from '../utils/posthog-helpers';
import formatPrice from '../utils/price/format-price';
import session from '../utils/session';
import serializeQueryParams from '../utils/url/serialize-query-params';

type Payload = {
    discount: number;
    extra: string | null;
    ga_client_id: string;
    ga_load_status: string;
    ga_track_id: string;
    internal_path: string;
    item_id: string | number | null;
    item_labels: string[];
    item_price: number;
    page_url: string;
    position: number;
    purchase_id: number;
    query: string;
    query_params: string;
    referrer: string;
    source: string;
    test_segment: string;
    total_results: number;
    track_sid: string;
    type: string;
    user_agent: string | null;
    user_device: string;
    user_id: string;
    utm_campaign: string;
    utm_content: string;
    utm_medium: string;
    utm_source: string;
    utm_term: string;
};

export type QualityEventData = Partial<Payload>;

const QEservice = {
    post: (data: { events: QualityEventData[] }) => axios.post(API_URLS.QUALITY_EVENTS, data)
};

const isEmpty = (obj: ParsedQuery) => !Object.keys(obj).length;

const setLabels = (item: Material) => {
    const itemLabels = [];

    if (item.bestInCategories && item.bestInCategories.length) {
        itemLabels.push('Bestseller');
    }

    if (isNewMaterial(item)) {
        itemLabels.push('New');
    }

    if (item.sale) {
        itemLabels.push('Sale');
    }

    if (itemLabels.length) {
        return itemLabels;
    }
};

const priceInCents = (p: string | number) => {
    let price = p;

    price = formatPrice(price);
    price = price.replace(',', '.');
    price = parseFloat(price);
    price *= 100;

    return Math.floor(price);
};

// eslint-disable-next-line
const filterParams = (obj: ParsedQuery) => {
    const searchObj = { ...obj };

    delete searchObj.utm_source;
    delete searchObj.utm_medium;
    delete searchObj.utm_term;
    delete searchObj.utm_content;
    delete searchObj.utm_campaign;
    delete searchObj.internal_path;
    delete searchObj.query;

    if (!isEmpty(searchObj)) {
        return searchObj;
    }
};

const getAbTestsData = () => {
    const ab_tests = {
        testSegment: window.storage.getItem('testSegment'),
        testSegment0: window.storage.getItem('testSegment0'),
        testSegment1: window.storage.getItem('testSegment1'),
        testSegment10: window.storage.getItem('testSegment10'),
        testSegment11: window.storage.getItem('testSegment11'),
        testSegment12: window.storage.getItem('testSegment12'),
        testSegment13: window.storage.getItem('testSegment13'),
        testSegment14: window.storage.getItem('testSegment14'),
        testSegment2: window.storage.getItem('testSegment2'),
        testSegment3: window.storage.getItem('testSegment3'),
        testSegment4: window.storage.getItem('testSegment4'),
        testSegment5: window.storage.getItem('testSegment5'),
        testSegment6: window.storage.getItem('testSegment6'),
        testSegment7: window.storage.getItem('testSegment7'),
        testSegment8: window.storage.getItem('testSegment8'),
        testSegment9: window.storage.getItem('testSegment9')
    };

    let res = {};

    Object.entries(ab_tests).forEach((entry) => {
        const [, value] = entry;

        if (value?.indexOf('{') > -1) {
            try {
                const posthogFlags = getPosthogFlagsCalled();

                res = { ...res, ...JSON.parse(value), ...posthogFlags };
            } catch (e) {
                console.log(e);
            }
        }
    });

    return res;
};

const isExactlyUiTest = () => {
    return isUITest() || window.storage.getItem('uiTest') !== null || window.location.search.indexOf('uiTest') > -1;
};

const envMixin = () => {
    const urlParams = serializeQueryParams();

    return {
        ab_tests: isExactlyUiTest() ? {} : getAbTestsData(),
        ga_client_id: cookiesManager.getItem('_ga'),
        ga_track_id: cookiesManager.getItem('_gid'),
        page_url: window.location.href,
        query: ((urlParams.query as string) || '').replace(/%sl%/g, '/'),
        query_params: filterParams(urlParams),
        referrer: document.referrer,
        track_sid: window.storage.getItem('track_sid') || null,
        user_agent: detectBrowser(),
        user_device: detectDevice(),
        user_id: window.parseInt(window.storage.getItem('userId')) || null,
        utm_campaign: urlParams.utm_campaign,
        utm_content: urlParams.utm_content,
        utm_medium: urlParams.utm_medium,
        utm_source: urlParams.utm_source,
        utm_term: urlParams.utm_term
    };
};

const qualityEvents = {
    extractMaterialsData(data: Material | Material[], params = {} as CatalogProps) {
        const extract = (item: Material, index = 0) => ({
            item_id: item.id,
            item_labels: setLabels(item),
            item_price:
                `${item.price}` === 'null' || `${item.price}` === 'undefined' ? undefined : priceInCents(item.price),
            position: typeof params.itemIndex === 'undefined' ? index + 1 : params.itemIndex + 1
        });

        if (Array.isArray(data)) {
            return data.map(extract);
        }

        return extract(data);
    },

    extractPurchasedMaterialsData(data: Material[]) {
        return data.map((item) => ({
            discount: item.discount,
            item_id: item.id,
            item_labels: setLabels(item),
            item_price: item.finalPrice,
            purchase_id: item.purchase_id
        }));
    },

    handleQEtrackError(results: QualityEventData[]) {
        if (!window.failedQE) {
            window.failedQE = [];
        }

        window.failedQE.push(results);
    },

    handleQEtrackSuccess(results: QualityEventData[]) {
        if (!window.QE) {
            window.QE = [];
        }

        window.QE.push(results);
    },

    postpone(obj: QualityEventData) {
        window.storage.setItem(JSON.stringify(obj));
    },

    send(
        payload: QualityEventData[],
        resolve: (value: void | PromiseLike<void>) => void,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reject: (reason?: any) => void
    ) {
        if (session.impersonated()) {
            resolve();

            return;
        }

        QEservice.post({
            events: payload
        }).then(
            // @ts-ignore
            (res = {}) => {
                // @ts-ignore
                if (res.track_sid) {
                    // @ts-ignore
                    window.storage.setItem('track_sid', res.track_sid);
                }

                this.handleQEtrackSuccess(payload);
                resolve();
            },
            () => {
                this.handleQEtrackError(payload);
                reject();
            }
        );
    },

    track(data: QualityEventData | QualityEventData[]): Promise<void> {
        if (isPrerender()) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            if (Array.isArray(data)) {
                const payload = data.map((item) => {
                    return {
                        ...item,
                        ...envMixin()
                    };
                });

                // @ts-ignore
                this.send(payload as QualityEventData[], resolve, reject);
            } else {
                const payload = {
                    ...envMixin(),
                    ...data
                };

                this.send([payload] as QualityEventData[], resolve, reject);
            }
        });
    },

    trackMaterialList(arr: QualityEventData[], additional: QualityEventData) {
        if (isPrerender()) {
            return;
        }

        const results = arr.map((item) =>
            Object.assign(item, {
                ...envMixin(),
                ...additional,
                extra: JSON.stringify({ referrer: window.sessionStorage.getItem(`material_${item.item_id}`) })
            })
        ) as QualityEventData[];

        arr.forEach((item) => {
            window.sessionStorage.removeItem(`material_${item.item_id}`);
        });

        return new Promise((resolve, reject) => {
            this.send(results, resolve, reject);
        });
    },

    trackPostponed(data: QualityEventData) {
        const postponed = window.storage.getItem('postponedQE')
            ? JSON.parse(window.storage.getItem('postponedQE'))
            : '';

        if (postponed) {
            this.track({
                ...data,
                ...postponed
            });

            window.storage.removeItem('postponedQE');
        }
    }
};

export default qualityEvents;
