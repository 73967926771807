import { useState } from 'react';
import abAvailability from '../services/ab-availability';
import serializeQueryParams from '../utils/url/serialize-query-params';
import isPrerender from '../utils/is-prerender';
import { ABTestProps, EXISTED_AB_TEST_GROUPS } from '../constants/ab-tests';

const testValues = {
    groups: {},
    features: {}
};

const cleanStoredDataForGroupFeatures = (features: string[]) => {
    // we also have testSegment without index
    cleanStoredDataForGroupFeaturesInTestSegment('testSegment', features);
    // 15 - count of test segments 0..14
    for (let i = 0; i < 15; i++) {
        cleanStoredDataForGroupFeaturesInTestSegment(`testSegment${i}`, features);
    }
};

const cleanStoredDataForGroupFeaturesInTestSegment = (testSegment: string, features: string[]) => {
    const storedData = window?.storage?.getItem(testSegment);

    if (features?.some((feature: string) => storedData?.includes(feature))) {
        let updatedData = {};

        try {
            const parsedStoredData = JSON.parse(storedData);

            features.forEach((feature: string) => {
                if (parsedStoredData[feature]) {
                    delete parsedStoredData[feature];
                }
            });

            updatedData = parsedStoredData;
        } catch {}

        window?.storage?.setItem(testSegment, JSON.stringify(updatedData));
    }
};

const storeUnleashGroupsData = (groupName: string, abTestKey: string) => {
    let data = {
        [groupName]: {
            features: EXISTED_AB_TEST_GROUPS[groupName],
            value: abTestKey
        }
    };
    const existedGroups = window?.storage?.getItem('unleashGroups');

    if (existedGroups) {
        try {
            const existedGroupsData = JSON.parse(existedGroups);

            data = Object.assign(existedGroupsData, data);
        } catch {}
    }

    window.storage.setItem('unleashGroups', JSON.stringify(data));
};

const getUnleashGroup = (group = '') => {
    return new Promise((resolve) => {
        if (!group) {
            return resolve('');
        }

        const existedGroups = window?.storage?.getItem('unleashGroups');
        let existedGroupData;

        if (existedGroups) {
            try {
                existedGroupData = JSON.parse(existedGroups)[group];

                if (
                    JSON.stringify(existedGroupData?.features?.sort()) ===
                    JSON.stringify(EXISTED_AB_TEST_GROUPS[group]?.sort())
                ) {
                    return resolve(existedGroupData.value);
                }
            } catch {
                existedGroupData = null;
            }
        }

        // @ts-ignore
        const featuresForCleaning = [...new Set([...(existedGroupData?.features || []), ...(EXISTED_AB_TEST_GROUPS[group] || [])])]

        // Clearing data for AB tests from the group before select new active test for current user
        if (featuresForCleaning.length) {
            cleanStoredDataForGroupFeatures(featuresForCleaning);
        }

        // Select group by random
        const groupVariants = EXISTED_AB_TEST_GROUPS[group];
        const randomIndex = Math.floor(Math.random() * groupVariants.length);
        const randomGroupVariant = groupVariants[randomIndex];

        storeUnleashGroupsData(group, randomGroupVariant);

        return resolve(randomGroupVariant);
    });
};

export const getUnleashTest = ({
    gaDimension,
    unleashKey = '',
    hardApplyParam = '',
    gaTestName = '',
    group = ''
}: ABTestProps) => {
    const params = serializeQueryParams();

    return new Promise((setValue) => {
        const param = params[hardApplyParam] || params[unleashKey];

        if (param) {
            const mapper = {
                0: 'O',
                1: 'B',
                2: 'C',
                3: 'D',
                4: 'E',
                5: 'F',
                6: 'G'
            };

            if (Number.isNaN(Number(param))) {
                setValue(param as string);
            } else {
                setValue(mapper[Number(param)]);
            }
        } else {
            const existedVersion = window?.storage?.getItem(gaDimension);

            if (gaTestName && group && gaTestName !== group) {
                const storedData = window?.storage?.getItem(gaDimension);

                try {
                    const parsedStoredData = JSON.parse(storedData);

                    parsedStoredData[gaTestName] = 'A: Default';
                    window?.storage?.setItem(gaDimension, JSON.stringify(parsedStoredData));
                } catch {
                    window?.storage?.setItem(
                        gaDimension,
                        JSON.stringify({
                            [gaTestName]: 'A: Default'
                        })
                    );
                }

                return setValue('A');
            }

            if (existedVersion?.indexOf(gaTestName) > -1) {
                let variant = existedVersion;

                if (gaTestName) {
                    try {
                        variant = JSON.parse(existedVersion)[gaTestName];
                    } catch {
                        variant = existedVersion.slice(0, existedVersion.indexOf(gaTestName));
                        window.storage.setItem(gaDimension, JSON.stringify({ [gaTestName]: variant }));
                    }
                }

                setValue(variant);
            } else {
                let variantName = 'A'; // By default AB test has original variant

                abAvailability
                    .get(unleashKey || gaDimension)
                    .then((response) => {
                        variantName = response?.variant?.name;
                        const variantValue = response?.variant?.payload?.value;

                        if (variantName === 'disabled') {
                            return;
                        }

                        if (variantName === 'Original') {
                            variantName = 'O';
                        }

                        if (variantValue?.[0] === ':') {
                            variantName += variantValue;
                        }

                        let data = { [gaTestName]: variantName };

                        try {
                            const gaData = JSON.parse(window.storage.getItem(gaDimension));

                            if (gaData && Object.keys(gaData).length < 10) {
                                gaData[gaTestName] = variantName;
                                data = gaData;
                            }
                        } finally {
                            window.storage.setItem(gaDimension, JSON.stringify(data));
                        }
                    })
                    .finally(() => {
                        setValue(variantName);
                    });
            }
        }
    });
};

const initUnleashGroup = (group = ''): Promise<string> => {
    return new Promise((resolve) => {
        if (!group) {
            return resolve('');
        }

        if (testValues.groups[group]?.loaded) {
            return resolve(testValues.groups[group]?.value);
        }

        if (!testValues.groups[group]?.loading) {
            testValues.groups[group] = {
                loading: true,
                promise: getUnleashGroup(group)
            };
        }

        return testValues.groups[group]?.promise.then((val: string) => {
            testValues.groups[group] = { loaded: true, value: val };
            resolve(val);
        });
    });
};

export const initUnleashAbTest = ({
    gaDimension,
    unleashKey = '',
    hardApplyParam = '',
    gaTestName = '',
    group = ''
}: ABTestProps): Promise<string> => {
    return new Promise((resolve) => {
        // Set default/original variant for prerender
        // The same trick as in the init() because in some cases we use this method (initUnleashAbTest) directly (not through init())
        if (isPrerender()) {
            return resolve('A');
        }

        if (testValues.features[gaTestName]?.loaded) {
            return resolve(testValues.features[gaTestName]?.value);
        }

        if (!testValues.features[gaTestName]?.loading) {
            testValues.features[gaTestName] = {
                loading: true,
                promise: getUnleashTest({ gaDimension, gaTestName, hardApplyParam, unleashKey, group })
            };
        }

        return testValues.features[gaTestName]?.promise.then((val: string) => {
            testValues.features[gaTestName] = { loaded: true, value: val };
            resolve(val);
        });
    });
};

const useUnleashAbTest = ({
    gaDimension,
    unleashKey = '',
    hardApplyParam = '',
    gaTestName = '',
    group = ''
}: ABTestProps) => {
    const [value, setValue] = useState('');

    const onGetValue = (val: string) => {
        const abTestVariant = val?.split(':')[0];

        setValue(abTestVariant);
    };

    const init = () => {
        const params = serializeQueryParams();
        const param = params[hardApplyParam] || params[unleashKey];

        // Set default/original variant for prerender
        if (isPrerender()) {
            onGetValue('A');
            
            return;
        }

        if (param) {
            initUnleashAbTest({ gaDimension, gaTestName, hardApplyParam, unleashKey }).then(onGetValue);
        } else {
            initUnleashGroup(group)
                .then((groupVariant) =>
                    initUnleashAbTest({ gaDimension, gaTestName, hardApplyParam, unleashKey, group: groupVariant })
                )
                .then(onGetValue);
        }
    };

    return { initUnleashTest: init, unleashVersion: value };
};

export default useUnleashAbTest;
