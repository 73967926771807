import cookieManager from './browser-storage/cookies';
import serializeQueryParams from './url/serialize-query-params';

type SentryType = typeof import('@sentry/browser');

const PERCENTAGE_OF_USERS_TO_TRACK = 10;
const STORAGE_KEY = 'withSentry';
const SENTRY_ENABLED = '1';
const SENTRY_DISABLED = '0';
let Sentry: SentryType;

const forceSentryLaunch = () => {
    const queryParams = serializeQueryParams();

    return Boolean(queryParams[STORAGE_KEY]);
};

const isUserInSegment = (): boolean => {
    const randomPercentage = Math.random() * 100;

    return randomPercentage < PERCENTAGE_OF_USERS_TO_TRACK;
};

const shouldSentryBeInitedForThisSegment = (): boolean => {
    const storedValue = cookieManager.getItem(STORAGE_KEY);

    if (storedValue === SENTRY_DISABLED) {
        return false;
    }

    if (storedValue === SENTRY_ENABLED || forceSentryLaunch()) {
        return true;
    }

    if (!storedValue && isUserInSegment()) {
        cookieManager.setItem(STORAGE_KEY, SENTRY_ENABLED);

        return true;
    }

    cookieManager.setItem(STORAGE_KEY, SENTRY_DISABLED);

    return false;
};

const errorTracker = {
    captureException: async (exception?: Error, highPriority: Boolean = false) => {
        if (highPriority && !Sentry) {
            await errorTracker.initSentry();
        }

        Sentry?.captureException(exception);
    },
    captureMessage: (message: string) => {
        Sentry?.captureMessage(message);
    },
    initSentry: async () => {
        if (process.env.REACT_APP_BLOCK_SENTRY) {
            return;
        }

        Sentry = await import('@sentry/browser');

        Sentry.init({
            beforeBreadcrumb(breadcrumb) {
                return breadcrumb.category === 'ui.input' ? null : breadcrumb;
            },
            dsn: 'https://dcd3b85576764aa6b953b8e8e3d68b90@sentry-01.lehrermarktplatz.de/4',
            environment: process.env.REACT_APP_BRANCH_NAME
        });
    },
    launch() {
        setTimeout(async () => {
            if (shouldSentryBeInitedForThisSegment()) {
                this.initSentry();
            }
        }, 0);
    }
};

export default errorTracker;
