import API_URLS from '../../constants/api-urls';
import Routes from '../../constants/routes';
import axios from '../../utils/axios';
import clearStorage from '../../utils/browser-storage/clear-storage';

export default function logout(redirectToLogin = false) {
    axios.get(API_URLS.LOGOUT).then(async () => {
        clearStorage();

        if (redirectToLogin) {
            window.locationReload(Routes.LOGIN.path);
        } else {
            window.location.reload();
        }
    });
}
