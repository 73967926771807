import { CURRENCIES_TO_HIDE_CENTS } from '../../constants/currency';

const convertToPriceWithNoCents = (priceInFloat: number): string => {
    return `${Math.floor(priceInFloat)}`;
};

const convertToPriceWithComma = (priceInFloat: number): string => {
    return priceInFloat.toFixed(2).replace('.', ',');
};

const formatPrice = (price: Price): string => {
    const currencyCode = window.storage.getItem('currencyCode');
    const centsToBeHidden = CURRENCIES_TO_HIDE_CENTS.includes(currencyCode);
    const priceInFloat = parseFloat(String(price).replace(' ', '').replace(',', '.'));

    if (centsToBeHidden && priceInFloat > 1) {
        return convertToPriceWithNoCents(priceInFloat);
    }

    return convertToPriceWithComma(priceInFloat);
};

export default formatPrice;
