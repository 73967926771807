/* eslint-disable sort-keys-fix/sort-keys-fix */
const API_URLS = {
    AGB: 'v3/agb/get-actual-agb',
    AGB_PUBLISHED_DATE: 'v3/agb/get-actual-agb-published-at',
    ACCEPT_AGB: 'v3/agb/accept-agb',
    AI_GET_HISTORY: 'v3/material-ai-assistant/description/history',
    AI_GENERATE: 'material-ai-assistant/description/generate',
    AI_ALLOWED: 'v3/material-ai-assistant/description/check',
    AI_QUIZ: 'v3/material-ai-assistant/quiz/generate',
    AI_QUIZ_DOWNLOAD: 'v3/material-ai-assistant/quiz/download',
    AI_TOOLS: 'v3/ai-tools/tools/query',
    AI_TOOLS_DOCUMENTS: 'v3/ai-tools/documents',
    AI_TOOLS_DOCUMENT_DOWNLOAD: 'v3/ai-tools/document/download',
    AI_TOOLS_DOCUMENT_GENERATE: 'v3/ai-tools/document/generate',
    AI_TOOLS_TITLE_GENERATE: 'v3/ai-tools/document/title',
    AI_TOOLS_DOCUMENT_DELETE: 'v3/ai-tools/document/delete-by-query',
    AI_TOOLS_REFINE: 'v3/ai-tools/document/refine',
    AI_TOOLS_CREATE_INTERACTIVE: 'v3/ai-tools/document/create-interactive',
    AI_TOOLS_SAVE_DOCUMENT: 'v3/ai-tools/document/update-by-query',
    IS_AGB_ACCEPTED: 'v3/agb/is-actual-agb-accepted',
    AUTHENTICATE: 'v3/oauth2/authenticate',
    AUTHORS: 'v1/authors',
    AUTHORS_MATERIAL_IDS: 'v3/materials/query',
    BLOGPOST: 'v3/feeds',
    BUNDLE: 'v1/bundle',
    CABINET: 'v1/cabinet',
    CABINET_MATERIALS: 'v1/cabinet/materials',
    COUNTRIES: 'v1/countries',
    CART: 'v1/cart/',
    CHANGE_EMAIL_CONFIRM: 'v3/change-email/confirm',
    CHANGE_EMAIL_REQUEST: 'v3/change-email/request',
    CHANGE_COUNTRY: '/v3/customer-contacts/create-country-change',
    REQUEST_CUSTOM_PRICE: '/v3/customer-contacts/create-price-increase',
    COMMENTS: 'v3/comments',
    CREATE_ADDRESS: 'v3/addresses/create',
    CREATE_FROM_UNIT: 'v3/materials/unit/create',
    CREATE_TAX_ID: 'v3/user-payment-details/create',
    MATERIALS_BY_IDS: 'v1/materials/by-ids',
    CREDITS_LIST: 'v3/credits/list',
    CURRENCY: 'v3/currencies/current',
    DOI: 'v1/user/double-opt-in',
    ELASTIC_SEARCH: 'v1/elastic',
    FEATURE_SWITCH: 'v3/settings/feature',
    AB_VARIANT: 'v3/settings/variant',
    PUBLIC_ADMIN_SETTINGS: 'v3/settings/public/query',
    FEATURED_AUTHORS: 'v1/authors-featured',
    FILTERS: 'v1/filters',
    FILES: 'v3/files',
    FREE_MATERIALS_LANDING: 'v1/stiftung-landing',
    GENERATE_VOUCHER: 'v3/campaign-voucher-generator/generate',
    GET_COUNTRY: 'v3/location/get-country',
    SET_COUNTRY: 'v3/location/set-country',
    GRADE_VALIDATION: 'v3/material-school-types/validation-rules',
    HELP_CENTER: 'v3/customer-contacts/create',
    HOMEPAGE_MATERIALS: 'v1/tabs/',
    IMAGE_UPLOAD: 'v2/images/upload',
    INVITE_CODE: 'v3/referrals/code',
    ITEM_TAGS: 'v3/tags/resource/sync',
    PAYMENT_ATTEMPT: 'v1/payment/attempt',
    KUSHKI_TRANSFER_CALLBACK: 'v1/payment/fetch/callback/',
    LIKES: 'v3/likes',
    LOGIN_BY_TOKEN: 'v1/login-by-token',
    LOGOUT: 'v3/oauth2/logout',
    MATERIAL: 'v1/materials',
    MATERIAL_SOURCES: 'v3/sources/query',
    MATERIAL_SOURCES_AUTOCOMPLETE: 'v3/material-sources/sources',
    MY_MATERIALS: 'v3/elastic-indexer/my-purchases/query',
    MY_MATERIALS_FILTERS: 'v1/purchases/filters',
    MY_UPLOADS_FILTERS: 'v1/own/filters',
    MY_ORDERS: 'v1/orders/my',
    MY_UPLOADS: 'v3/elastic-indexer/my-materials-legacy/query',
    MATERIALS_LEGACY_PUBLIC: 'v3/elastic-indexer/materials-legacy/public/query',
    NEWSLETTER_SUB: 'v1/users/newsletter-subscription',
    NEW_LANDING: 'v3/landing_pages',
    KEYWORD_LANDING: 'v3/keyword-landing-pages',
    ORDER_ITEMS: 'v1/order',
    PAYMENT_ATTEMPT_PAYPALPLUS: 'v1/payment/attemptPayPalPlus',
    PAYMENT_OPTIONS: 'v1/payment/options',
    PODCAST_AUTHORS: 'v1/podcast-authors',
    QUALITY_EVENTS: 'v3/events/track',
    RATING: 'v3/rating/',
    RECOMMENDED_MATERIALS: 'v3/follow-the-author/get-recommended-materials',
    UPDATE_NOTIFICATIONS: 'v3/update-notifications',
    MY_RECOMMENDED: 'v3/elastic-indexer/my-recommended',
    REGISTER_BY_TOKEN: 'v1/register-by-token',
    REGISTRATION_CONFIRMATION: 'v1/register/check-email',
    SAVED_HOURS: 'v1/saved-hours',
    SCHOOL_MENU: 'v3/mega-menu/get',
    SUBJECTS_MENU: 'v3/mega-menu/get-subjects',
    SCHOOL_MENU_AUTHORS: 'v1/authors/header/top',
    SEARCH: 'v3/suggest/prefix',
    SEARCH_FILTERS: 'v1/search/filters',
    SIGN_UP: 'v1/register',
    SUBSCRIPTION: {
        FOLLOW: 'v3/subscriptions/subscribe',
        FOLLOW_SCHOOL: 'v3/subscriptions/subscribe/school',
        FOLLOW_ANONYMOUS: 'v3/subscriptions/anonymous/subscribe',
        UNFOLLOW_ANONYMOUS: 'v3/subscriptions/anonymous/unsubscribe',
        GET_FOLLOWED_ANONYMOUS: 'v3/subscriptions/anonymous/list',
        ANONYMOUS_PERSIST: 'v3/subscriptions/anonymous/persist',
        GET_FOLLOWED: 'v1/subscriptions',
        PREFERENCES: 'v3/subscriptions/preferences',
        SET_PREFERENCES: 'v3/subscriptions/set-preferences',
        UNFOLLOW: 'v3/subscriptions/unsubscribe',
        UNFOLLOW_SCHOOL: 'v3/subscriptions/unsubscribe/school'
    },
    FOLDERS: {
        GET: 'v3/folders/public/query',
        FOLDER_MATERIALS: 'v3/folders/materials/query',
        MATERIALS: 'v3/folders/materials',
        CREATE: 'v3/folders/create',
        SCHOOL: 'v3/folders/school',
        UPDATE: 'v3/folders/update-by-query',
        DELETE: 'v3/folders/delete-by-query',
        ARCHIVE: 'v3/folders/archive/add-by-query',
        UNARCHIVE: 'v3/folders/archive/delete-by-query'
    },
    OWN_MATERIALS: {
        UPDATE: 'v3/materials/update-by-query',
        SET_PRICE: 'v3/materials/price/create'
    },
    TAGGED_AUTHORS: 'v1/authors-tagged',
    TAGS_SEARCH: 'v3/tags/query',
    TAXONOMY: 'v1/taxonomy',
    UPDATE_ADDRESS: 'v3/addresses/{id}/update',
    UPDATE_TAX_ID: 'v3/user-payment-details/{id}/update',
    MATERIAL_KEYWORD_LANDING_PAGES: 'v3/material-keyword-landing-pages',
    USER_DATA: 'v1/user',
    USER_DETAILS: 'v3/user-details',
    UTM_TRACKER: 'v1/utm-tracker',
    VALIDATE: 'v3/oauth2/validate',
    VALIDATE_INVITE_CODE: 'v3/referrals/check',
    WISHLIST: 'v3/elastic-indexer/wishlist',
    SCHOOL_WISHLIST: 'v3/elastic-indexer/wishlist/school/query',
    SCHOOL_WISHLIST_FILTERS: 'v1/wishlist/school/filters',
    INTERACTIVE_INDEXER: 'v3/elastic-indexer/interactive/query',
    ANONYMOUS_WISHLIST: 'v3/elastic-indexer/anonymous-wishlist',
    WISHLIST_FILTERS: 'v1/wishlist/filters',
    PAYMENT_ATTEMPT_CALLBACK: 'v1/payment/callback/PayPalCheckout',
    PAYMENT_ATTEMPT_CALLBACK_ADYEN: 'v1/payment/callback/adyen/internal',
    MATERIAL_FEEDBACKS: 'v3/material-feedbacks',
    FONTS: 'v1/fonts',
    FONTS_ACCESS: 'v3/fonts-access',
    PRICE_RECOMMENDATION: 'v3/material-price-recommender',
    PAYMENT_PP_VAULT_AGREEMENT: 'v1/payment/paypal-vault/update',
    BULK_STOP_SALE: 'v3/sales/bulk-delete'
};

export default API_URLS;
