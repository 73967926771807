import { Dispatch } from 'redux';
import API_URLS from '../../../constants/api-urls';
import axios from '../../../utils/axios';
import imagePathCreator from '../../../utils/image-path-creator';
import prerenderMeta from '../../../utils/prerender-meta';
import authorService from '../services/author-service';

// import { USER_GET } from '../../../reducers/user';

export type AuthorDetails = {
    publicName: string;
    imagePath?: string;
    description?: string;
    profileBackgroundPath?: string;
    croppedProfileBackgroundPath?: string;
};

export type AuthorState = {
    details: AuthorDetails;
    id: number | null;
    notFound: boolean;
    imgLoading?: boolean;
    loading?: boolean;
    profileBackgroundData?: string;
};

export type AuthorAction = {
    type: string;
    payload?: Partial<AuthorState | AuthorDetails>;
};

export const AUTHOR_GET = 'author/GET';
export const AUTHOR_RESET = 'author/RESET';
export const AUTHOR_UPDATE_DETAILS = 'author/UPDATE_DETAILS';
export const AUTHOR_UPDATE_LOADING = 'author/UPDATE_LOADING';

const initialState: Author = {
    details: { publicName: 'Autor' },
    id: null,
    notFound: false
} as unknown as Author;

export default (state = initialState, action: AuthorAction) => {
    switch (action.type) {
        // case USER_GET:
        //     return getStateByUser(state, action.user)
        case AUTHOR_GET:
        case AUTHOR_UPDATE_LOADING:
            return {
                ...state,
                ...action.payload
            };
        case AUTHOR_UPDATE_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.payload
                }
            };
        case AUTHOR_RESET:
            return initialState;
        default:
            return state;
    }
};

// const getStateByUser = (state, user) => {
//     if (state.id === null || (state.id !== null && Number(state.id) === Number(user.id))) {
//
//         return {
//             ...state,
//             ...user,
//             details: {
//                 ...user.details,
//                 imagePath: imagePathCreator.authorImage(user, { h: 210, w: 'auto', q: 100 }),
//                 profileBackgroundPath: convertBgPath(user.details.profileBackgroundPath)
//             }
//
//         }
//     }
//
//     return { ...state };
// }

export const resetAuthor = () => (dispatch: Dispatch) => {
    dispatch({
        type: AUTHOR_RESET
    });
};

export const updateAuthorDetails = (payload: Partial<AuthorDetails>) => (dispatch: Dispatch) => {
    dispatch({
        payload,
        type: AUTHOR_UPDATE_DETAILS
    });

    return axios.post(`${API_URLS.USER_DATA}/details`, { ...payload });
};

export const uploadUserAvatar = (file: File) => (dispatch: Dispatch) => {
    if (!file) {
        return;
    }

    const data = new FormData();
    const url = URL.createObjectURL(file);

    dispatch({
        payload: { imagePath: url, avatar_path: url },
        type: AUTHOR_UPDATE_DETAILS
    });

    if (file?.name) {
        data.append('file', file, file.name);
    }

    return axios.post(`${API_URLS.USER_DETAILS}/avatar`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const updateAuthorBackground = (data: FormData) => (dispatch: Dispatch) => {
    dispatch({ payload: { imgLoading: true, loading: true }, type: AUTHOR_UPDATE_LOADING });
    axios
        .post('v3/user-details/background', data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((r) => {
            dispatch({ payload: { imgLoading: true, loading: false }, type: AUTHOR_UPDATE_LOADING });
            dispatch({
                payload: {
                    profileBackgroundData: JSON.parse(r?.profile_background_data),
                    profileBackgroundPath: convertBgPath(r?.profile_background_path)
                },
                type: AUTHOR_UPDATE_DETAILS
            });
        })
        .catch(() => {
            dispatch({ payload: { imgLoading: false, loading: false }, type: AUTHOR_UPDATE_LOADING });
        });
};

export const updateBgLoading = (imgLoading: boolean) => (dispatch: Dispatch) => {
    dispatch({ payload: { imgLoading }, type: AUTHOR_UPDATE_LOADING });
};

export const removeAuthorBackground = () => (dispatch: Dispatch) => {
    axios.post('v3/user-details/remove-background').then(() => {
        dispatch({
            loading: false,
            payload: {
                croppedProfileBackgroundPath: '',
                profileBackgroundData: {},
                profileBackgroundPath: ''
            },
            type: AUTHOR_UPDATE_DETAILS
        });
    });
};

export const getAuthor = (params: { id: number }) => (dispatch: Dispatch) => {
    // const { user } = getState().main;

    authorService
        .get(params.id)
        .then((response) => {
            const author: Author = response as Author;

            author.details.avatar_path = author.details.imagePath;
            author.details.imagePath = imagePathCreator.authorImage(author, { h: 200, q: 100, w: 'auto' });
            author.details.croppedProfileBackgroundPath = croppedBgPath(author.details.profileBackgroundPath);
            author.details.profileBackgroundPath = convertBgPath(author.details.profileBackgroundPath);
            // author.isPrivate = true;
            dispatch({
                payload: { ...author, notFound: false },
                type: AUTHOR_GET
            });
            // const { user } = getState().main;
            // const { author: currentAuthor } = getState();
            //
            // if (!user.loaded || (user.loaded && (Number(user.id) !== Number(author.id)) || !currentAuthor.id)) {
            //     author.details.imagePath = imagePathCreator.authorImage(author, { h: 210, w: 'auto', q: 100 });
            //     author.details.profileBackgroundPath = convertBgPath(author.details.profileBackgroundPath);
            //     // author.isPrivate = true;
            //     dispatch({
            //         type: AUTHOR_GET,
            //         payload: { ...author, notFound: false }
            //     });
            // }
        })
        .catch(() => {
            prerenderMeta.set(404);

            dispatch({
                payload: { notFound: true },
                type: AUTHOR_GET
            });
        });
    // if (Number(params.id) === Number(user.id)) {
    //     dispatch({
    //         type: AUTHOR_GET,
    //         payload: { ...user, notFound: false }
    //     });
    // } else {
    //     axios
    //         .get(`v1/profile/${params.id}`)
    //         .then((r) => {
    //             const author = r.data.items[0];
    //             const { user } = getState().main;
    //             const { author: currentAuthor } = getState();
    //
    //             if (!user.loaded || (user.loaded && (Number(user.id) !== Number(author.id)) || !currentAuthor.id)) {
    //                 author.details.imagePath = imagePathCreator.authorImage(author, { h: 210, w: 'auto', q: 100 });
    //                 author.details.profileBackgroundPath = convertBgPath(author.details.profileBackgroundPath);
    //                 // author.isPrivate = true;
    //                 dispatch({
    //                     type: AUTHOR_GET,
    //                     payload: { ...author, notFound: false }
    //                 });
    //             }
    //         })
    //         .catch(() => {
    //             prerenderMeta.set(404);
    //
    //             dispatch({
    //                 type: AUTHOR_GET,
    //                 payload: { notFound: true }
    //             });
    //         });
    // }
};

const croppedBgPath = (path: string) =>
    path
        ? imagePathCreator.croppedImg(
              { url: path },
              {
                  h: 204,
                  q: 80,
                  type: 'webp',
                  w: window.innerWidth > 1200 ? 1200 : window.innerWidth
              }
          )
        : '';

const convertBgPath = (path: string) =>
    path
        ? imagePathCreator.optimizedImg(
              { preview: path },
              {
                  h: 'auto',
                  q: 80
              }
          )
        : '';
