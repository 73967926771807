import session from '../session';

const clearStorage = (): void => {
    session.clearAccessToken();

    window.storage.removeItem('isLoggedIn');
    window.storage.removeItem('isSeller');
    window.storage.removeItem('userSlug');
    window.storage.removeItem('FrMatReq');
    window.storage.removeItem('impersonated');
    window.storage.removeItem('oauth_user_id');
    window.storage.removeItem('__paypal_storage__');

    try {
        window.sessionStorage.clear();
    } catch (e) {}
};

export default clearStorage;
